<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">数据字典</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="设备类型">
					<a-select v-model="query.class_type" class="a-select" placeholder="请选择类型" @change="handleChange">
						<a-select-option :value="0">
							{{ '全部' }}
						</a-select-option>
						<a-select-option :value="1">
							{{ '消防' }}
						</a-select-option>
						<a-select-option :value="2">
							{{ '监控' }}
						</a-select-option>
						<a-select-option :value="3">
							{{ '门禁' }}
						</a-select-option>
						<a-select-option :value="4">
							{{ '网络设备' }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input v-model="query.device_type_name" placeholder="设备名称" />
				</a-form-model-item>
			</a-row>
			<row>
				<div class="add-btn-box">
					<a-button type="primary" @click="toAdd">
						<a-icon type="plus-circle" />
						<span>添加类型</span>
					</a-button>
				</div>
			</row>
		</a-form-model>

		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px" v-viewer>
			<span slot="class_type" slot-scope="text, record">
				{{ text == 1 ? '消防' : (text == 2 ? '监控' : (text == 3 ? '门禁' : '网络设备')) }}
			</span>
			<span slot="icon" slot-scope="text, record">
				<div class="img-box">
					<img :src="$domain + record.icon" class="img-qr img-icon"
						style="filter: drop-shadow(#7AF4FF 100px 0)">
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<div class="img-box">
					<img :src="$domain + record.icon" class="img-qr img-icon"
						style="filter: drop-shadow(#656565 100px 0)">
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<div class="img-box">
					<img :src="$domain + record.icon" class="img-qr img-icon"
						style="filter: drop-shadow(#DC4444 100px 0)">
				</div>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
				</a-space>
			</span>
		</a-table>

		<div>
			<a-drawer :title="ed_label + '数据字典'" placement="right" width="750" :closable="false" :visible="visible"
				@close="onClose">
				<a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" ref="dictionaryForm" :model="form"
					:rules="dictionaryRules">
					<a-form-model-item label="设备类型" prop="class_type">
						<a-select v-model="form.class_type" class="a-select" placeholder="请选择类型">
							<a-select-option :value="1">
								{{ '消防' }}
							</a-select-option>
							<a-select-option :value="2">
								{{ '监控' }}
							</a-select-option>
							<a-select-option :value="3">
								{{ '门禁' }}
							</a-select-option>
							<a-select-option :value="4">
								{{ '网络设备' }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item prop="device_type_name" label="设备名称">
						<a-input v-model="form.device_type_name"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="device_type_name" label="展示级别">
						<a-select v-model="form.low_show" style="width: 120px">
							<a-select-option :value="((count + 33) * 0.5)" v-for="count in 11"
								:key="((count + 33) * 0.5)">
								{{ ((count + 33) * 0.5) }}
							</a-select-option>
						</a-select>
						<span class="ant-form-text">
							<a style="color: #D9D9D9">—</a>
						</span>
						<a-select v-model="form.high_show" style="width: 120px">
							<a-select-option :value="((count + 33) * 0.5)" v-for="count in 11"
								:key="((count + 33) * 0.5)">
								{{ ((count + 33) * 0.5) }}
							</a-select-option>
						</a-select>
						<a-switch :checked="form.warn_show == 1" @change="warnChange">
							<a-icon slot="checkedChildren" type="check" />
							<a-icon slot="unCheckedChildren" type="close" />
						</a-switch>
						仅报警显示
					</a-form-model-item>
					<a-form-model-item label="icon" prop="icon" :wrapperCol="{ span: 19 }">
						<a-upload :action="$domain + '/admin/v1/index/uploadImgLocal'" list-type="picture-card"
							@change="handleHead" :show-upload-list="false">
							<img class="place-img" :src="$domain + form.icon" v-if="form.icon" />
							<div v-else>
								<a-icon type="plus" />
								<div class="ant-upload-text">请上传图片</div>
							</div>
						</a-upload>
					</a-form-model-item>
					<a-form-model-item prop="remark" label="备注">
						<a-input v-model="form.remark"></a-input>
					</a-form-model-item>
				</a-form-model>
				<div :style="{
					position: 'absolute',
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e8e8e8',
					padding: '10px 16px',
					textAlign: 'right',
					left: 0,
					background: '#fff',
					borderRadius: '0 0 4px 4px',
				}">
					<a-button style="marginRight: 8px" @click="onClose">
						取消
					</a-button>
					<a-button type="primary" @click="submitInfo">
						提交
					</a-button>
				</div>
			</a-drawer>
		</div>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'
import { get } from 'js-cookie'
import { type } from 'os'

export default {
	data() {
		return {
			label: '',
			query: {
				page: 1,
				limit: 10
			},
			ed_label: null,
			dictionary_id: null,
			visible: false,
			pagetotal: 0,
			checkValue: [],
			checkOptions: [{
				label: '开启',
				value: 1
			},
			{
				label: '关闭',
				value: -1
			}
			],
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '类型',
				dataIndex: 'class_type',
				scopedSlots: {
					customRender: 'class_type'
				}
			},
			{
				title: '设备名称',
				dataIndex: 'device_type_name',
				width: "15%"
			},
			{
				title: 'icon',
				scopedSlots: {
					customRender: 'icon'
				},
				width: "20%"
			},
			{
				title: '备注信息',
				dataIndex: 'remark',
				width: "30%"
			},
			{
				title: '操作',
				scopedSlots: {
					customRender: 'action'
				}
			}
			],
			data: [],
			form: {},
			confirmLoading: false,
			dictionaryRules: {
				class_type: [
					{
						required: true,
						message: '请选择类别',
						trigger: 'blur'
					}
				],
				device_type_name: [
					{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}
				],
				icon: [
					{
						required: true,
						message: '请上传图片',
						trigger: 'blur'
					}
				],
			},
		}
	},
	created() {
		this.getList()
	},
	methods: {
		getType(type) {
			if (type == 1) {
				this.label = '消防'
			} else if (type) {
				this.label = '监控';
			} else {
				this.label = '门禁'
			}
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		toEdit(item) {
			this.visible = true;
			this.dictionary_id = item.dictionary_id;
			this.ed_label = '修改'
			this.form = item
			// this.getType(item.class_type)
		},
		toAdd() {
			this.visible = true;
			this.ed_label = '添加'
		},
		onClose() {
			this.visible = false;
			this.dictionary_id = null;
			this.ed_label = null
			this.form = {}
		},
		toChangeList(id) {
			this.$router.push({
				path: '/changeList',
				query: {
					id: id,
				}
			})
		},
		toContrastList(id) {
			this.$router.push({
				path: '/contrastList',
				query: {
					id: id,
				}
			})
		},
		getList() {
			this.$post(domain + '/admin/v1/dictionary/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.getList();
		},
		handleHead(res) {
			this.uploading = true
			if (res.file.status == "done") {
				this.uploading = false
				if (res.file.response.code != 0) {
					this.$message.error(res.file.response.msg)
				} else {
					this.form.icon = res.file.response.data.really_path;
				}
			}
		},
		submitInfo() {
			this.$refs.dictionaryForm.validate(valid => {
				console.log('fdsjdfkjsfl', this.form)
				if (valid) {
					this.confirmLoading = true
					let action = null;
					if (this.dictionary_id != null) {
						this.form.dictionary_id = this.dictionary_id
						action = 'update'
					} else {
						action = 'add'
					}
					this.$post("dictionary/" + action, this.form)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.visible = false
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		handleChange(value) {
			this.query.class_type = value
			console.log(`selected ${value}`);
		},
		warnChange(checked) {
			if (checked) {
				this.form.warn_show = 1
			} else {
				this.form.warn_show = -1
			}
		},
	}
}
</script>

<style lang="less" scoped>
.img-box {
	overflow: hidden;
	display: inline-block;

	.img-icon {
		transform: translateX(-100px);
		filter: drop-shadow(blue 100px 0)
	}
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
<style lang="less">
.fengmap-box {
	position: relative;
	width: 70vw;
	height: 70vh;
	overflow: hidden;

	#fengmap {
		position: absolute;
		top: 0;
		width: 100%;
		bottom: -50px;
	}
}

.feng-icon-div {
	width: 30px;
	height: 30px;
	overflow: hidden;
	background-size: 100% 100%;
	cursor: pointer;
	border-radius: 50%;
	border: 5px solid #333;
	background-color: #fff;
	box-sizing: content-box;
	margin-left: -5px;
	margin-top: -5px;

	&:hover {
		border-color: #2B6AE0;

		.img-fengmap-icon {
			filter: drop-shadow(#2B6AE0 100px 0);
		}
	}
}

.img-fengmap-icon {
	width: 24px;
	height: 24px;
	margin-left: 3px;
	margin-top: 3px;
	filter: drop-shadow(rgb(255, 0, 0) 100px 0);
	transform: translateX(-100px);
	pointer-events: none;
	user-select: none;
}

.img-fengmap-icon-green {
	width: 24px;
	height: 24px;
	margin-left: 3px;
	margin-top: 3px;
	filter: drop-shadow(rgb(2, 182, 26) 100px 0);
	transform: translateX(-100px);
	pointer-events: none;
	user-select: none;
}
</style>
